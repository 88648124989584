import { useState } from "react";
import styles from "./BingoCard.module.css";
import Modal from "../Modal/Modal";
import { Update } from "../../api/Bricks";

export default function BingoCard({ selectedCard }) {
    const { card_id, bricks, points } = selectedCard;
    const [expanded, setExpanded] = useState(null);

    const closeModal = () => setExpanded(null);

    return (
        <div className={styles.cardContainer}>
            <div className={styles.header}>
                <h2>Bingo Card #{card_id}</h2>
                <p>Score: {points} pts</p>
            </div>
            <div className={styles.bingoCard}>
                {bricks.map((cell) => (
                    <BingoCell key={cell.brick_id} cell={cell} onClick={() => setExpanded(cell)} />
                ))}
            </div>
            {expanded && (
                <Modal closeModal={closeModal}>
                    <ExpandedCell cell={expanded} />
                </Modal>
            )}
        </div>
    );
}

const flagCell = async (updatedItem) => {
    const item = {
        brick_id: updatedItem.brick_id,
        flagged: 1,
    };
    const result = await Update(item);
    if (result.status !== "success") {
        alert("Failed to save changes.");
    }
};

function BingoCell({ cell, onClick }) {
    const cellClass = `
        ${styles.cell} 
        ${cell.status ? styles["status-true"] : ""} 
        ${cell.boost_level === 2 ? styles["boost-2"] : cell.boost_level === 3 ? styles["boost-3"] : ""}
    `;

    return (
        <div className={cellClass} style={{ gridArea: `auto / auto / span 1 / span 1` }} onClick={onClick}>
            {cell.title}
        </div>
    );
}

function ExpandedCell({ cell }) {
    return (
        <div>
            <div style={{ display: "flex", gap: "10px", alignItems: "center", justifyContent: "center" }}>
                <h3 className={styles.title}>{cell.title}</h3>
                <button style={{ fontSize: "12px", padding: "5px" }} onClick={() => flagCell(cell)}>
                    Flag
                </button>
            </div>
            <p className={styles.description} style={{ whiteSpace: "pre-wrap" }}>
                {cell.description}
            </p>
            <div className={styles.status}>
                <span className={`${styles.statusLabel} ${cell.status ? styles.claimed : styles.locked}`}>
                    {cell.status ? "Claimed" : "Locked"}
                </span>
                {cell.boost_level > 1 && (
                    <span className={`${styles.boost} ${cell.boost_level === 2 ? styles.silver : styles.gold}`}>
                        Boost: {cell.boost_level}x
                    </span>
                )}
            </div>
        </div>
    );
}
